
import { Component } from 'vue-property-decorator';
import { IFee } from '@/entity/fee/fee';
import { vxm } from '@/store';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { projectApi } from '@/wapi/project-api';
import { IProject } from '@/entity/project/project';
import RHBaseClass from '@c/rh/rh-class-base.vue';
import { feeApi } from '@/wapi/fee-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import CurrencyInput from '@c/shared/currency-input.vue';
import PercentageInput from '@c/shared/percentage-input.vue';
import { planificationApi } from '@/wapi/planification-api';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { employeeApi } from '@/wapi/employee-api';
import { IEmployee } from '@/entity/shared/employee';

@Component({
    components: {
        CurrencyInput,
        PercentageInput
    }
})
export default class EffectiveFeeInformation extends RHBaseClass {
    private filterData: string = '';
    private isBusy: boolean = true;
    private isSaving: boolean = false;


    private dismissSecs: number = 5;
    private tableKey: number = 0;
    private dismissCountDown: number = 0;
    private showDismissibleAlert: boolean = false;

    private projectPlaceholder: string = 'Sélectionnez le projet';
    private phasePlaceholder: string = 'Sélectionnez la phase';

    private fees: IFee[] = [];
    private filteredFees: IFee[] = [];
    private projects: IProject[] = [];
    private employee: IEmployee = {} as IEmployee;
    private anyIsTrue: boolean = false;

    private feeFields: Array<IBootstrapTableColumn | string> = [

        {
            label: '',
            key: 'isChecked',
            thStyle: 'width:50px',
            tdClass: 'checkbox'
        },
        {
            label: 'Date',
            key: 'creationDate',
            sortable: true,
            // formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR'),
            thStyle: 'width:250px'
        },
        { label: 'Projet', key: 'projectId', thStyle: 'width:230px' },
        { label: 'Mission', key: 'contractLineId', thStyle: 'width:230px' },
        { label: 'Libellé', key: 'label', sortable: true, thStyle: 'width:230px' },
        { label: 'Frais kilométriques', key: 'isDistance', thStyle: 'width:170px' },
        { label: 'Distance (en Km)', key: 'distance', sortable: true, thStyle: 'width:170px' },
        { label: 'Montant TTC (€)', key: 'fees', sortable: true, thStyle: 'width:200px', formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'TVA', key: 'tax', thStyle: 'width:120px' },
        // { label: 'Export', key: 'isChecked', sortable: true, thStyle: 'width:100px' },
        { label: '', key: 'dummy', sortable: false, thStyle: 'width:75px' }
        // { label: 'Indice Km', key: 'indexKm', sortable: true }
    ];

    get isReadOnly(): boolean {
        return (vxm.user.isAssociate || vxm.user.isDaf) && !vxm.user.isAdmin && !vxm.user.isStudioManager 
        && !vxm.user.isCP && !vxm.user.isRH
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.feeFields;
    }

    get checkField(): boolean {
        return this.fees.some(fee=>fee.contractLineId==0 || fee.contractLineId == null)
    }

    get projectId(): number {
        let id: number = 0;
        if (this.projects) {
            this.projects.forEach(selection => {
                id = selection.id;
            });
            return id;
        } else {
            return 0;
        }
    }

    private countDownChanged(dismissCountDown: number): void {
        this.dismissCountDown = dismissCountDown;
    }

    private showAlert(): void {
        this.dismissCountDown = this.dismissSecs;
    }

    private setDistanceFees(fee: IFee): IFee {
        fee.fees = (this.employee.indexKm as number) * (fee.distance as number);
        return fee;
    }

    private resetValue(fee: IFee): IFee {
        fee.distance = 0;
        fee.fees = 0;
        fee.tax = 20;
        return fee;
    }

    private onRowSelected(item: IFee[], _index: number, _event: Event): void {
        this.fees.forEach(fee => {
            if (item.indexOf(fee) > -1) {
                fee.isChecked = true;
            } else {
                fee.isChecked = false;
            }
        });
        if (item && item.length > 0) {
            this.anyIsTrue = true;
        } else {
            this.anyIsTrue = false;
        }
    }

    private onFiltered(filteredItems: IFee[]) {
        this.filteredFees = filteredItems;
    }

    private addFeeLine(): void {
        const lineToAdd = {
            id: 0,
            employeeId: this.id,
            projectId: 0,
            projectDesignation: '',
            contractLineId: 0,
            contractLineDesignation: '',
            label: '',
            isDistance: false,
            distance: 0,
            fees: 0,
            tax: 20,
            total: 0,
            phaseOption: [
                // {
                //     id: 0,
                //     code: '',
                //     label: ''
                // }
            ],
            creationDate: new Date(),
            isChecked: false
        };
        this.fees?.push(lineToAdd);
    }

    private deleteFeeLine(item: IFee): void {
        const index = this.fees?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.fees?.splice(index, 1);
        }
    }

    async setPhaseOptions(fee: IFee): Promise<void> {
        const call = await planificationApi.getAllPhasesByProjectId(fee.projectId ?? 0);
        fee.phaseOption = call.datas?.map((x, index) => {
            const phase: ISelectListOption = {
                id: x.contractLineId,
                code: 'CO' + index,
                label: x.contractLine.designation ?? ''
            };
            return phase;
        });
    }

    async createFee(): Promise<void> {
        if (this.fees) {
            const callData = await feeApi.createFee(
                this.fees.filter(x => x.id === 0),
                this.id
            );
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
            }
        }
    }

    async updateFee(): Promise<void> {
        if (this.fees) {
            const callData = await feeApi.updateFee(
                this.fees.filter(x => x.id !== 0),
                this.id
            );
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
            }
        }
    }

    async reloadFees(): Promise<void> {
        this.isBusy = true; 
        const callFees = await feeApi.getAllByUserId(this.id);
        
        if (isCallValidAndNotCancelled<IFee[]>(callFees)){
            var callFeesData = callFees.datas as IFee[];
            callFeesData.forEach(fee => {
                fee.isChecked = false;
                var options = this.getPhaseOption(fee.projectId);
                options.then(result => {
                    fee.phaseOption = result;
                    this.tableKey++;
                });
            });

            this.fees = [...callFeesData];
        }
        this.isBusy = false;
    }


    async save(): Promise<void> {
        if (this.isSaving) return;
        this.isSaving = true;

        const newFees = this.fees.filter(element => element.id === 0);
        const existingFees = this.fees.filter(element => element.id !== 0);

        if (newFees.length) await this.createFee();
        if (existingFees.length) await this.updateFee();

        this.$bvToast.toast('Saisie des frais effectuée avec succès', {
            title: 'Frais réels',
            variant: 'success',
            solid: true
        });

        await this.reloadFees();
        this.isSaving = false;
    }

    private redirectToPdf(): void {
        if (this.anyIsTrue) {
            this.$router.push({
                name: 'fee-pdf',
                params: { fees: JSON.stringify(this.fees.filter(x => x.isChecked === true)) }
            });
        } else {
            this.showAlert();
        }
    }

    async getPhaseOption(projectId: number): Promise<ISelectListOption[]> {
        const call = await planificationApi.getAllPhasesByProjectId(projectId ?? 0);
        var phaseOptions = call.datas?.map((x, index) => {
            const phase: ISelectListOption = {
                id: x.contractLineId,
                code: 'CO' + index,
                label: x.contractLine.designation ?? ''
            };
            return phase;
        }) || [];

        return phaseOptions;
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Frais');
        vxm.app.changeTitleExt('réels');
        vxm.user.checkIsAssociate();
        vxm.user.checkIsRH();
        vxm.user.checkIsUser();
        vxm.user.checkIsCP();
        vxm.user.checkIsStudioManager();
        vxm.user.checkIsDaf();
        vxm.user.checkIsAdmin();
        const callEmployee = await employeeApi.getBase(this.id);
        if (isCallValidAndNotCancelled<IEmployee>(callEmployee)) {
            this.employee = callEmployee?.datas as IEmployee;
        }

        const callProject = await projectApi.getAllBase();
        if (isCallValidAndNotCancelled<IProject[]>(callProject)) {
            this.projects = (callProject?.datas ?? []) as IProject[];
        }

        const callFees = await feeApi.getAllByUserId(this.id);
        if (isCallValidAndNotCancelled<IFee[]>(callFees)) {
            var callFeesData = callFees.datas as IFee[];
            callFeesData.forEach(fee => {
                    fee.isChecked = false;
                    var options = this.getPhaseOption(fee.projectId);
                    options.then(result=>{
                        fee.phaseOption = result;
                        this.tableKey++;
                    });
                });

        this.fees=[...callFeesData];
        }

        this.isBusy = false;    
    }
}
