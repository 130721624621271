
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISupplier } from '@/entity/shared/supplier';
import { ISupplierAddress } from '@/entity/shared/supplier-address';
import { supplierApi } from '@/wapi/supplier-api';
import { vxm } from '@/store';
import { IReferential } from '@/entity/shared/referential';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { IContact } from '@/entity/shared/contact';
import { IGraphRequestParameter } from '@/entity/shared/graph-request-parameter';
import { groupNamingConventionManager } from '@t/group-naming-convention-manager';
import { moduleApiGraph } from '@t/module-api-graph';
import { deepCopy } from '@t/object';
import { ISupplierContact } from '@/entity/shared/supplier-contact';

interface IVM<T> {
    uniq: number;
    element: T;
}

@Component({
    components: {
        InputElement
    }
})
export default class SupplierInformation extends Vue {
    @Prop({ required: true }) id!: string;
    private code: string = 'SPL';

    @Validations()
    validations = {
        supplier: {
            label: { required }
        }
    };

    private newContact: IContact = {};
    private newAddress: ISupplierAddress = {};
    private autoIncrementUniqAddress: number = 1;
    private removedContacts: ISupplierContact[] = [];

    private supplier: NU<ISupplier> = {} as ISupplier;
    private autoIncrementUniqContact: number = 1;

    get addresses(): NU<IVM<ISupplierAddress>[]> {
        if (this.supplier && this.supplier.supplierAddresses) {
            return this.supplier.supplierAddresses.map(x => {
                return { uniq: this.autoIncrementUniqContact++, element: x } as IVM<ISupplierAddress>;
            });
        }
        return [];
    }

    get contacts(): NU<IVM<ISupplierContact>[]> {
        if (this.supplier && this.supplier.supplierContacts) {
            return this.supplier.supplierContacts.map(x => {
                return { uniq: this.autoIncrementUniqContact++, element: x } as IVM<ISupplierContact>;
            });
        }
        return [];
    }

    private groupId: string = '';
    private d: any = {};

    get thirdPartyType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyType;
    }

    get thirdPartyAddressType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyAddressType;
    }

    get isReadOnly(): boolean {
        return !vxm.user.isAdmin;
    }

    private getLabelofThirdPartyAddressType(id: number): string {
        if (!this.thirdPartyAddressType || this.thirdPartyAddressType.length === 0 || id === null) {
            return '';
        } else {
            return this.thirdPartyAddressType.filter(x => x.id === id)[0].label;
        }
    }

    private addSupplierAddressLine(): void {
        this.newAddress = {
            id: undefined,
            tempId: this.autoIncrementUniqAddress++,
            code: '',
            thirdPartyId: Number(this.id),
            thirdPartyAddressTypeId: null,
            label: '',
            postalCode: '',
            city: '',
            country: ''
        };
        (this.$refs['add-supplier-address-modal'] as any).show();
    }

    private addAddress(): void {
        if (this.newAddress.id === undefined) {
            this.newAddress.id = 0;
        }
        if (this.supplier && this.supplier.supplierAddresses) {
            const index = this.supplier.supplierAddresses.findIndex(
                x =>
                    ((x.id as number) > 0 && x.id === this.newAddress.id) ||
                    (x.id === 0 && x.tempId === this.newAddress.tempId)
            );
            if (index > -1) {
                this.supplier.supplierAddresses.splice(index, 1, this.newAddress);
            } else {
                this.supplier.supplierAddresses.push(this.newAddress);
            }
        }
        (this.$refs['add-supplier-address-modal'] as any).hide();
    }

    private deleteSupplierAddressLine(item: ISupplierAddress): void {
        const index = this.supplier?.supplierAddresses?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.supplier?.supplierAddresses?.splice(index, 1);
        }
    }

    private editSupplierAddressLine(item: ISupplierAddress): void {
        if (this.supplier && this.supplier.supplierAddresses) {
            this.newAddress = deepCopy<ISupplierAddress>(item);
        }
        (this.$refs['add-supplier-address-modal'] as any).show();
    }

    private addContactLine(): void {
        this.newContact = { mail: '', givenName: '', surName: '', tempId: this.autoIncrementUniqContact++  };
        (this.$refs['add-supplier-contact-modal'] as any).show();
    }

    private editSupplierContactLine(item: IContact): void{
        if (this.supplier && this.supplier.supplierContacts) {
            this.newContact = deepCopy<IContact>(item);
        }
        (this.$refs['add-supplier-contact-modal'] as any).show();
    }

    private async addContact(): Promise<void> {
        if (this.supplier && this.supplier.supplierContacts) {
            const index = this.supplier.supplierContacts.findIndex(
                x =>
                    ((x.id != null && x.id === this.newContact.id) ||
                    (x.id == null && x.tempId === this.newContact.tempId)
                    ));
            if (index > -1) {
                this.supplier.supplierContacts.splice(index, 1, this.newContact);
            } else {
                this.supplier.supplierContacts.push(this.newContact);
            }
        }
        (this.$refs['add-supplier-contact-modal'] as any).hide();
    }

    private deleteContactLine(item: IVM<ISupplierContact>): void {
        const index = this.contacts?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.removedContacts.push(...(this.contacts as IVM<ISupplierContact>[]).splice(index, 1).map(x => x.element));
        }
    }

    async updateSupplier(): Promise<void> {
        if (this.supplier) {
            const callData = await supplierApi.patchBase(this.supplier.id + '', this.supplier);
            if (isCallValidAndNotCancelled(callData)) {
                if (this.contacts) {
                    // await this.createContact(
                    //     this.contacts?.filter(x => !x.element.id).map(x => x.element),
                    //     this.supplier.id as number
                    // );
                }

                // if (this.removedContacts && this.removedContacts.length > 0) {
                //     await supplierApi.removeContactInADGroup(this.removedContacts, this.supplier.id as number);
                //     this.removedContacts.splice(0, this.removedContacts.length);
                // }
                this.$bvToast.toast('Mise à jour du fournisseur effectuée avec succès', {
                    title: `Supplier: ${this.supplier.label}`,
                    variant: 'success',
                    solid: true
                });
            }
        }
    }

    async createSupplier(): Promise<void> {
        if (this.supplier) {
            const callData = await supplierApi.createSupplier(this.supplier);
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
                // await this.createContact(
                //     this.contacts?.map(x => x.element),
                //     Number((callData.datas as any).thirdPartyId)
                // );
                this.groupId = (callData.datas as any).groupId;
                await this.$router.push({
                    name: 'supplier-information',
                    params: { id: (callData.datas as any).thirdPartyId }
                });
            }
        }
        this.supplier = {} as ISupplier;
        const supplierCallData = await supplierApi.getByIdAndCode(this.id, this.code);
        if (isCallValidAndNotCancelled<ISupplier>(supplierCallData)) {
            this.supplier = supplierCallData?.datas;
            if (this.supplier) {
                vxm.app.changeTitleExt('Informations de ' + this.supplier.label);
                this.groupId = await this.getGroupIdByName(this.supplier.label as string);
                // if (this.groupId) {
                //     const urlGraph = '/groups/' + this.groupId + '/members?$select=id,displayName,mail';
                //     const graphData = await moduleApiGraph.Client.api(urlGraph).get();
                //     this.contacts = graphData.value.map(x => {
                //         return {
                //             uniq: this.autoIncrementUniqContact++,
                //             element: {
                //                 id: x.id,
                //                 displayName: x.displayName,
                //                 mail: x.mail
                //             }
                //         } as IVM<IContact>;
                //     });
                // }
                this.$bvToast.toast('Création du fournisseur effectuée avec succès', {
                    title: `Supplier: ${this.supplier?.label}`,
                    variant: 'success',
                    solid: true
                });
            }
        }
    }

    async createContact(contact: NU<IContact[]>, supplierId: number): Promise<void> {
        if (contact) {
            const callData = await supplierApi.createContactInADGroup(contact, supplierId);
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
            }
        }
    }

    async save(): Promise<void> {
        if (this.supplier?.id === 0) {
            this.createSupplier();
        } else {
            this.updateSupplier();
        }
    }

    async fetchReferencial(): Promise<void> {
        await vxm.referential.fetchThirdPartyType();
        await vxm.referential.fetchThirdPartyAddressType();
    }

    private newSupplier(): void {
        const supplierAddresses: ISupplierAddress[] = [] as ISupplierAddress[];
        const supplierContacts: ISupplierContact[] = [] as ISupplierContact[];
        this.supplier = {
            id: 0,
            code: this.code,
            label: '',
            thirdPartyTypeId: this.thirdPartyType?.filter(x => x.code === 'SPL')[0].id,
            parentId: null,
            directLine: '',
            webSite: '',
            mail: '',
            comment: '',
            supplierAddresses: supplierAddresses,
            supplierContacts: supplierContacts
        } as ISupplier;
    }

    private getGraphField(p: keyof IGraphRequestParameter): string {
        return p;
    }

    async getGroupIdByName(groupName: string): Promise<string> {
        const gn = groupNamingConventionManager.encodeGroupName(groupName);
        const getResult = await moduleApiGraph.Client.api('/groups')
            .filter(`${this.getGraphField('displayName')}+eq+'${gn}'`)
            .get();
        if (getResult.value && getResult.value[0]) {
            return getResult.value[0].id;
        } else {
            return '';
        }
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Fournisseurs');
        await vxm.user.checkIsAdmin();
        await this.fetchReferencial();
        if (this.id === '0') {
            vxm.app.changeTitleExt("Création d'un nouveau fournisseur");
            this.newSupplier();
        } else {
            if (this.code === undefined || this.code === null) {
                this.code = 'SPL';
            }
            const supplierCallData = await supplierApi.getByIdAndCode(this.id, this.code);
            if (isCallValidAndNotCancelled<ISupplier>(supplierCallData)) {
                this.supplier = supplierCallData?.datas;
                // if (this.supplier) {
                //     vxm.app.changeTitleExt('Informations de ' + this.supplier.label);
                //     this.groupId = await this.getGroupIdByName(this.supplier.label as string);
                //     if (this.groupId) {
                //         const urlGraph = '/groups/' + this.groupId + '/members?$select=id,displayName,mail';
                //         const graphData = await moduleApiGraph.Client.api(urlGraph).get();
                //         this.contacts = graphData.value.map(x => {
                //             return {
                //                 uniq: this.autoIncrementUniqContact++,
                //                 element: {
                //                     id: x.id,
                //                     displayName: x.displayName,
                //                     mail: x.mail
                //                 }
                //             } as IVM<IContact>;
                //         });
                //     }
                // }
            }
        }
    }
}
