
import { IContract } from '@/entity/contract/contract';
import { ICreateContract } from '@/entity/contract/create-contract';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { contractApi } from '@/wapi/contract-api';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { BTable } from 'bootstrap-vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { appTokenMgr } from '@t/employee-app-role';
import frenchLocale from 'date-fns/locale/fr';
import { ICancellableResult } from '@t/ajax-wrapper';

@Component({
    components: {
        InputElement
    }
})
export default class ContractList extends Vue {
    @Prop({ required: true }) projectId!: string;
    @Prop({ required: true, default: false }) isReadOnly!: boolean;
    private createContractObject: ICreateContract = {} as ICreateContract;
    private filteredContracts: NU<IContract[]> = [];
    private filterData: string = '';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private contractsCurrentPage: number = 1;
    private contractsPerPage: number = 10;
    private promiseExport: boolean = false;

    @Validations()
    validations = {
        createContractObject: {
            description: { required }
        }
    };

    get contracts(): NU<IContract[]> {
        const list = vxm.project.contractsList;
        this.filteredContracts = list;
        return list;
    }

    get totalContracts(): number {
        if (this.filteredContracts && this.filteredContracts.length > 0) {
            return this.filteredContracts.length;
        }
        return 0;
    }

    get sumTotalExcTax(): number {
        return Number(
            (
                this.filteredContracts
                    ?.map((x: IContract) => x.totalExcTax)
                    .reduce((x: number, y: number) => x + y, 0) ?? 0
            ).toFixed(2)
        );
    }

    get sumTotalExcTaxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.sumTotalExcTax);
    }

    get displayedContractsPage(): Array<IContract> {
        if (this.filteredContracts != null) {
            return this.filteredContracts?.slice((this.contractsCurrentPage - 1) * 10, this.contractsCurrentPage * 10);
        }
        return [];
    }

    get sumAmountInvoiced(): string {
        const invoices = vxm.project.invoicesList;
        const invoicesTotalExcTax = invoices
            ?.filter((x) => x.contractId !== null && this.filteredContracts?.find((y) => y.id === x.contractId))
            ?.map((x) => x.totalExcTax);
        let amount = 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length === 1) {
            amount = invoicesTotalExcTax[0] ?? 0;
        }
        if (invoicesTotalExcTax && invoicesTotalExcTax.length > 1) {
            amount = invoicesTotalExcTax.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) ?? 0;
        }
        const percentage = Number(
            (amount > 0 && this.sumTotalExcTax > 0 ? (amount / this.sumTotalExcTax) * 100 : 0).toFixed(2)
        );
        return `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)} 
            (${new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(percentage / 100)})`;
    }

    private amountInvoiced(item: IContract): string {
        const invoices = vxm.project.invoicesList;
        const invoicesTotalExcTax = invoices?.filter(x => x.contractId === item.id)?.map(x => x.totalExcTax);
        let amount = 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length === 1) {
            amount = invoicesTotalExcTax[0] ?? 0;
        }
        if (invoicesTotalExcTax && invoicesTotalExcTax.length > 1) {
            amount = invoicesTotalExcTax.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) ?? 0;
        }
        const percentage = Number(
            (amount > 0 && item.totalExcTax > 0 ? (amount / item.totalExcTax) * 100 : 0).toFixed(2)
        );
        return `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)} 
            (${new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(percentage / 100)})`;
    }

    private isBusy: boolean = true;

    private editContract(item: IContract[], _index: number, _event: Event): void {
        this.$emit('selectedContract', item[0]);
    }

    private baseFields: IBootstrapTableColumn[] = [
        { label: 'Référence', key: 'reference', sortable: true },
        { label: 'Désignation', key: 'description', sortable: true },
        {
            label: 'Date de création',
            key: 'date',
            sortable: true,
            formatter: (val: Date, key?: string, item?) => format(new Date(String(val)), 'dd/MM/yy')
        },
        {
            label: 'Total HT',
            key: 'totalExcTax',
            sortable: false,
            tdClass: 'moneyCell',
            thClass: 'moneyCell',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)
        },
        {
            label: 'Facturé HT',
            key: 'amountInvoiced',
            tdClass: 'moneyCell',
            thClass: 'moneyCell',
            sortable: false,
            formatter: (val: number, key?: string, item?) => this.amountInvoiced(item)
        }
    ];

    @Watch('$route.params.id')
    updateProject (newVal, _oldVal): void {
        if(newVal) {
            this.getContractList()
        }
    }

    private async mounted(): Promise<void> {
        this.getContractList();
        this.isBusy = false;
    }

    private async getContractList(): Promise<void> {
        await vxm.project.fetchContracts(this.projectId);
    }

    private async updateContractList(): Promise<void> {
        await vxm.project.updateContracts(this.projectId);
    }

    public showModal(): void {
        this.createContractObject = {} as ICreateContract;
        const contractNumber = '00' + ((this.contracts?.length ?? 0) + 1);
        const projectTrigram = vxm.project.projectData?.trigram;
        this.createContractObject.reference = `${projectTrigram?.toUpperCase()}${contractNumber.substr(
            contractNumber.length - 3,
            contractNumber.length
        )}`;
        this.createContractObject.description = `${projectTrigram?.toUpperCase()}_`;
        this.createContractObject.projectId = +this.projectId;
        (this.$refs['create-contract-modal'] as any).show();
    }

    public async createContract(): Promise<void> {
        const result = await contractApi.createContract(this.createContractObject);
        if (result.cancelled === false && !result.error && result.datas) {
            (this.$refs['create-contract-modal'] as any).hide();
            this.updateContractList().then((x) => {
                (this.$refs['contract-table'] as BTable).selectRow((this.contracts?.length ?? 0) - 1);
            });
        }
    }

    async exportListContract(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listContracts = this.filteredContracts!.map((x) => x.id);
        const listIds =
        await this.generateListContract(
            contractApi.exportContractData(listContracts as any, sortKeySelected as string, sortKeyDescSelected as any),
            `Liste_contrats${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd-HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    private async generateListContract(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.baseFields;
    }

    private onFiltered(filteredItems: IContract[]) {
        this.filteredContracts = filteredItems;
        this.contractsCurrentPage = 1;
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        let a, b;
        if (key === 'description') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll('_', '-').toLowerCase(), b.replaceAll('_', '-').toLowerCase());
        }
    }
}
