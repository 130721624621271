
import { IInvoiceListLine } from '@/entity/invoice/invoice-list';
import { IStudio } from '@/entity/project/studio';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { invoiceApi } from '@/wapi/invoice-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { appTokenMgr, IEmployeeAppRoleManager } from '@t/employee-app-role';
import { NU } from '@t/type';
import { isAfter, isEqual } from 'date-fns';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICancellableResult } from '@t/ajax-wrapper';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { vxm } from '@/store';

@Component({
    components: {}
})
export default class InvoiceBaseList extends Vue {
    private columnPlaceholder: string = 'Nouvelle colonne';
    private invoices: IInvoiceListLine[] = [];
    private filteredInvoices: IInvoiceListLine[] = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private addedAdditionalFields: IBootstrapTableColumn[] = [];
    private selectedFieldToAdd: NU<IBootstrapTableColumn> = null;

    private startDate: Date = this.getBaseDate();
    private endDate: Date | null = null;

    get listOfFilters() {
        return vxm.lists.filters('invoices-list-state');
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    get additionalFieldChoice(): Array<IBootstrapTableColumn> {
        return vxm.lists.additionnalFieldChoice('invoices-list-state');
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return vxm.lists.listFields('invoices-list-state');
    }

    get filteredDataByColumn(): IInvoiceListLine[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);
        if (filtersApplied.length > 0) {
            this.filteredInvoices = this.invoices.filter((p) =>
                filtersApplied.every((f) => this.filterListByColumn(f, p))
            );
            return this.invoices.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }
        this.filteredInvoices = this.invoices.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        return this.invoices;
    }

    @Watch('selectedFieldToAdd')
    public addColumn(column: string, _oldVal: string): void {
        return vxm.lists.addColumn({ listKey: 'invoices-list-state', column });
    }

    setTimeToBegin(date: Date | string): Date {
        const dateObj = new Date(date);
        var newDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 2, 0, 0, 0);
        return newDate;
    }

    setTimeToEnd(date: Date | string): Date {
        const dateObj = new Date(date);
        var newDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() + 1, 1, 59, 59, 999);
        return newDate;
    }

    private async fetchDataWithDate() {
        this.isBusy = true;
        const resInvoices = await invoiceApi.getGlobalInvoiceList(
            this.setTimeToBegin(new Date(this.startDate)),
            this.endDate != null ? this.setTimeToEnd(new Date(this.endDate)) : null
        );

        if (isCallValidAndNotCancelled<Array<IInvoiceListLine>>(resInvoices) && resInvoices.datas != null) {
            this.invoices = resInvoices.datas;
        }
        this.isBusy = false;
    }

    public deleteColumn(column: string): void {
        return vxm.lists.removeColumn({ listKey: 'invoices-list-state', column });
    }

    async mounted() {
        vxm.app.changeTitleMain('Projets');
        vxm.app.changeTitleExt('Liste des factures');
        vxm.lists.init('invoices-list-state');
        await this.fetchDataWithDate();
    }

    private getBaseDate() {
        const current = new Date();

        const d = new Date();
        d.setMonth(9);
        d.setDate(1);
        d.setFullYear(current.getFullYear());

        if (isAfter(d, current)) {
            d.setFullYear(d.getFullYear() - 1);
        }

        /** TEST */
        // d.setFullYear(2021);
        // d.setMonth(2);
        // d.setDate(3);
        // d.setHours(3);
        // d.setMinutes(0);
        // d.setSeconds(0);
        /** /TEST */

        return d;
    }

    private onFiltered(filteredItems: IInvoiceListLine[]) {
        this.filteredInvoices = filteredItems;
    }

    public filterList(): void {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);
        if (filtersApplied.length > 0) {
            this.filteredInvoices = this.invoices.filter((p) =>
                filtersApplied.every((f) => this.filterListByColumn(f, p))
            );
            this.invoices.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }
        this.filteredInvoices = this.invoices;
    }

    async exportInvoices(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listIds =
            this.filteredInvoices && this.filteredInvoices.length !== this.invoices.length
                ? this.filteredInvoices.map((x) => x.id)
                : this.filteredDataByColumn && this.filteredDataByColumn.length !== this.invoices.length
                    ? this.filteredDataByColumn.map((x) => x.id)
                    : this.invoices.map((x) => x.id);
        const listAdds = this.addedAdditionalFields.map((x) => x.label);
        await this.generateReport(
            invoiceApi.exportInvoiceBaseListData(
                listIds,
                listAdds as any,
                sortKeySelected as string,
                sortKeyDescSelected as any,
                new Date(this.startDate),
                this.endDate != null ? new Date(this.endDate) : null
            ),
            `Liste_des_factures_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    public filterListByColumn(key: string, x: IInvoiceListLine): boolean {
        const containsCheck = [
            'studio',
            'trigram',
            'contractReference',
            'invoiceReference',
            'client',
            'company',
            'bank',
            'name'
        ];
        const dateCheck = ['date', 'dueDate', 'paymentDate'];
        const numberCheck = ['amountExcTax', 'amountIncTax'];

        if (containsCheck.find((x) => x === key)) {
            return x[key]?.toUpperCase().includes(this.listOfFilters[key].value.toUpperCase());
        } else if (dateCheck.find((x) => x === key)) {
            return new Date(x[key]).toLocaleDateString('fr-FR').includes(this.listOfFilters[key].value);
        } else if (numberCheck.find((x) => x === key)) {
            return this.listOfFilters[key].value === x[key];
        }
        return false;
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        let b0, a0;
        let a, b;
        if (key === 'trigram' || key === 'designation') {
            const a = itemA[key];
            const b = itemB[key];
            return this.strcmp(a.toUpperCase(), b.toUpperCase());
        } else if (key === 'clients' || key === 'agencies') {
            a = itemA[key];
            b = itemB[key];
            if (a.length === 0) {
                a0 = '';
            } else if (a[0].label === undefined) {
                a0 = '';
            } else a0 = a[0].label;
            if (b.length === 0) {
                b0 = '';
            } else if (b[0].label === undefined) {
                b0 = '';
            } else b0 = b[0].label;
            return this.strcmp(a0, b0);
            // } else if (key === 'studioManagerId') {
            //     a = itemA[key];
            //     if (this.graphManagers[a] === '') {
            //         const elementA: MgtPerson = this.$refs['studioManager' + a] as MgtPerson;
            //         this.graphManagers[a] =
            //             elementA && elementA.id ? elementA.personDetails?.displayName ?? 'zzzzzzzzzzzz' : '';
            //     }
            //     if (this.graphManagers[a]) {
            //         a0 = this.graphManagers[a].toLowerCase();
            //     }
            //     b = itemB[key];
            //     if (this.graphManagers[b] === '') {
            //         const elementB: MgtPerson = this.$refs['studioManager' + b] as MgtPerson;
            //         this.graphManagers[b] =
            //             elementB && elementB.id ? elementB.personDetails?.displayName ?? 'zzzzzzzzzzzz' : '';
            //     }
            //     if (this.graphManagers[b]) {
            //         b0 = this.graphManagers[b].toLowerCase();
            //     }
            //     if (a0 === undefined) a0 = '';
            //     if (b0 === undefined) b0 = '';
            //     return this.strcmp(a0.toUpperCase(), b0.toUpperCase());
        }
    }
}
